<template>
  <div class="container" @click="tap">
    <div class="heading-container relative">
      <h1 class="heading" :class="flashClass">d.tap</h1>
      <button class="top-right" @click.stop="toggleMute">
        <i :class="['fa', isMuted ? 'fa-volume-mute' : 'fa-volume-up']"></i>
      </button>
    </div>
    <div class="subheading">click or tap any key</div>
    <div class="content">
      <!-- Your content goes here -->
      <div @click.stop="showToast(bpm)">
        <span class="big">{{ bpm }}</span
        ><span> BPM</span>
      </div>
      <div @click.stop="showToast(ms)">
        <span class="big">{{ ms }}</span>
        <div class="relative">
          <span> ms</span>
          <span class="absolute">♩</span>
        </div>
      </div>
      <div @click.stop="showToast(Math.round(ms / 1.5))">
        <span class="big">{{ Math.round(ms / 1.5) }}</span>
        <div class="relative">
          <span> ms</span>
          <span class="absolute">.♪</span>
        </div>
      </div>
      <div @click.stop="showToast(Math.round(ms / 2))">
        <span class="big">{{ Math.round(ms / 2) }}</span>
        <div class="relative">
          <span class="relative"> ms</span>
          <span class="absolute">♪</span>
        </div>
      </div>
    </div>
    <div v-show="isVisible" class="toast-container">
      {{ message }}
    </div>
  </div>
</template>

<script>
import { onMounted, ref } from "vue";
import Toast from "./Toast.vue";

export default {
  components: {
    Toast,
  },
  setup() {
    const bpm = ref(0);
    const ms = ref(0);
    const flashClass = ref("");
    let interval;
    const isMuted = ref(true);
    let first = true;
    const isVisible = ref(false);
    const message = ref("");

    const AudioContext = window.AudioContext || window.webkitAudioContext;
    let audioContext = null;
    let oscillator = null;

    let taps = [];

    const body = document.body;

    body.addEventListener("keypress", () => {
      tap();
    });

    const toggleMute = () => {
      isMuted.value = !isMuted.value;
      console.log(isMuted.value);

      if (first) {
        first = false;
        audioContext = new AudioContext();
      }

      if (isMuted.value) {
        audioContext.suspend();
      } else {
        audioContext.resume();
      }
    };

    const playMetronome = () => {
      // If the oscillator is already playing, stop it to avoid overlapping sounds
      if (oscillator) {
        oscillator.stop();
      }

      // Create a GainNode for volume control
      const gainNode = audioContext.createGain();

      // Create a new oscillator
      oscillator = audioContext.createOscillator();

      // Set the oscillator type to a square wave for a percussive sound
      oscillator.type = "square";

      // gainNode.gain.value = 0.01;

      // Connect the oscillator to the gain node
      oscillator.connect(gainNode);

      // Connect the oscillator to the audio output (the speakers)
      oscillator.connect(audioContext.destination);

      // Start the oscillator to play the sound
      oscillator.start();

      // Automatically stop the oscillator after a short duration to create a click-like sound
      const duration = 50; // Adjust the duration for the desired click sound
      oscillator.stop(audioContext.currentTime + duration / 1000);
    };

    const flash = () => {
      flashClass.value = "flash";
      // Remove the class after a short delay to reset the animation
      setTimeout(() => {
        flashClass.value = "";
      }, 100);
    };

    const showToast = async (msg) => {
      try {
        await navigator.clipboard.writeText(msg);
        message.value = msg + " copied to clipboard";
        isVisible.value = true;
        setTimeout(() => {
          isVisible.value = false;
        }, 5000);
      } catch (err) {
        console.error("Failed to copy: ", err);
      }
    };

    const tap = () => {
      flashClass.value = "flash";
      // Remove the class after a short delay to reset the animation
      setTimeout(() => {
        flashClass.value = "";
      }, 100);

      const currentTime = Date.now();
      if (
        currentTime - taps[taps.length - 1] >
        taps[taps.length - 1] - taps[taps.length - 2] + 100
      ) {
        console.log("CLEAR");
        bpm.value = 0;
        ms.value = 0;
        taps = [];
      }
      taps.push(currentTime);

      if (taps.length > 1) {
        const totalTime = taps.reduce((acc, tap, index) => {
          if (index > 0) {
            acc += tap - taps[index - 1];
          }
          return acc;
        }, 0);

        const averageTimeBetweenBeats = totalTime / (taps.length - 1);
        const bpmCalc = 60000 / averageTimeBetweenBeats; // Convert to BPM (60000 milliseconds = 1 minute)
        bpm.value = Math.round(bpmCalc);
        // ms.value = Math.round(averageTimeBetweenBeats);
        //try this instead
        ms.value = Math.round(60000 / bpm.value);
      }

      if (ms.value != 0) {
        clearInterval(interval);
        interval = setInterval(() => {
          flash();
          if (!isMuted.value) {
            playMetronome();
          }
        }, ms.value);
      } else {
        clearInterval(interval);
      }
    };

    return {
      bpm,
      ms,
      tap,
      flashClass,
      isMuted,
      toggleMute,
      isVisible,
      message,
      showToast,
    };
  },
};
</script>

<style>
.relative {
  position: relative;
  display: inline-block;
}

.absolute {
  font-size: 32px;
  position: absolute;
  top: -34px;
  left: 4px;
}
.container {
  height: 100%;
  width: 100%;
  /* Use flexbox to center the content vertically and horizontally */
  display: flex;
  flex-direction: column;
  align-items: center; /* Centers content horizontally */
  cursor: pointer;
  user-select: none;
}

/* Style the heading */
.heading {
  font-size: 72px;
  font-weight: 700;
}

.subheading {
  font-size: 14px;
}

/* Style the content section if needed */
.content {
  /* Add your styles here, or remove this block if no additional styling is required */
  padding-top: 48px;
  text-align: left;
}
h1 {
  font-size: 48px;
}

.big {
  font-size: 72px;
  font-weight: 700;
}

.medium {
  font-size: 48px;
}
.small {
  font-size: 24px;
}

/* CSS animation for flashing effect */
@keyframes flash-animation {
  0%,
  100% {
    color: inherit; /* Use the text color of the parent element */
  }
  50% {
    color: rgb(167, 167, 167); /* Change to the desired flash color */
  }
}

.flash {
  animation: flash-animation 0.25s infinite;
}

button {
  background-color: transparent;
  border: none;
  cursor: pointer;
  font-size: 24px;
}

.heading-absolute {
  position: absolute;
  top: 36px;
  right: -40px;
}

.fa {
  color: rgb(41, 41, 41); /* Change the color to your desired color */
  /* Add any other styles you need here */
}

h1 {
  margin: 0;
}

.top-right {
  position: fixed;
  top: 6px;
  right: 6px;
  /* padding: 10px 20px; */
  font-size: 24px;
}

.toast-container {
  width: 100%;
  height: 50px;

  font-size: 14px;
  font-weight: 700;
  /* background-color: rgb(41, 41, 41); */
}
</style>