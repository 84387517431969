<template>
  <Tap />
</template>

<script>
import Tap from "./components/Tap.vue";

export default {
  name: "App",
  components: {
    Tap,
  },
};
</script>

<style>
#app {
  font-family: Roboto, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: rgb(41, 41, 41);
  height: 100%;
  width: 100%;
}

html,
body {
  margin: 0;
  padding: 0;
  height: 100%;
  background-color: rgb(219, 207, 207);
}
</style>
