<template>
  <div v-if="isVisible" class="toast-container">
    <div class="toast" :class="type">{{ message }}</div>
  </div>
</template>

<script>
export default {
  props: {
    type: {
      type: String,
      default: "success",
    },
    message: {
      type: String,
      required: true,
    },
    duration: {
      type: Number,
      default: 3000, // Default duration for the toast (3 seconds)
    },
  },
  data() {
    return {
      isVisible: false,
    };
  },
  watch: {
    message() {
      this.show();
    },
  },
  methods: {
    show() {
      this.isVisible = true;
      setTimeout(() => {
        this.isVisible = false;
      }, this.duration);
    },
  },
};
</script>

<style>
/* Customize the toast container and styles here */
.toast-container {
  position: fixed;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 9999;
}

.toast {
  padding: 10px 20px;
  border-radius: 5px;
  font-size: 16px;
  color: white;
  text-align: center;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

/* Customize the toast types */
.success {
  background-color: #5cb85c;
}

.error {
  background-color: #d9534f;
}

.info {
  background-color: #5bc0de;
}

.warning {
  background-color: #f0ad4e;
}
</style>